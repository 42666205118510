import * as React from 'react';
import { Component } from 'react';
import * as styles from './LandingPageGallery.module.scss';
import { HomeQuery_contentfulPageHome_preloadPlaceholderImage, LandingPageGalleryFragment_landingGallery } from '../../gatsby-queries';
import { UICircleProgress } from '../../ui/UICircleProgress';
import { Pause24, Play24 } from "@carbon/icons-react";
import { gsap, Sine, Linear, TweenMax, Power2 } from 'gsap/dist/gsap';
import { CSSRulePlugin } from 'gsap/dist/CSSRulePlugin';
import { RatioUtil, Rect } from '../../utils/RatioUtil';
import { EASE_CUSTOM_IN_OUT } from '../../utils/Constants';
import { GatsbyImageWrapper } from '../ui/GatsbyImageWrapper';
import { graphql } from 'gatsby';
import { Swipeable } from 'react-touch';
import $ from 'jquery/dist/jquery.min';
import { navigate } from '@reach/router';

const SLIDE_DURATION = 10;
const initialState = { currentSlideIndex: -1, currentLocation: null, playing: true, muted: true, currentVideo: null, currentMobileVideo: null, currentVideoPlaceholder: null, currentVideoLink: null, currentVideoCtaText: null };
type State = Readonly<typeof initialState>;

let visited = typeof window !== 'undefined' && window.localStorage.getItem('currentPromo')

class LandingPageGallery extends Component<
	{ items: LandingPageGalleryFragment_landingGallery[]; placeholder: HomeQuery_contentfulPageHome_preloadPlaceholderImage; promo: any; promotionalPlaceholder: any; promotionalVideo: any; animationDirection: 1 | -1 },
	State
> {
	readonly state: State = initialState;

	private slides: HTMLElement[] = [];
	private slideItems: Array<NodeListOf<HTMLElement>> = [];
	private slideMediaWrappers: HTMLElement[] = [];
	private uiCircles: UICircleProgress[] = [];
	private wrapperRef: HTMLDivElement;
	// @ts-ignore
	private VideoWrapperRef: HTMLDivElement;

	public wrapperHeight: number = 0;
	private videos: HTMLVideoElement[] = [];
	private videoContainer: HTMLElement;
	private videoControls: HTMLElement;
	private uiCirclesAnimation: any;

	private reTargetVideo(currentPromo) {
		if(currentPromo) {
			typeof window !== 'undefined' && window.localStorage.setItem("currentPromo", currentPromo);
		} else {
			typeof window !== 'undefined' && window.localStorage.removeItem("currentPromo");
		}
	}

	componentDidMount() {
		gsap.registerPlugin(CSSRulePlugin);

		if(this.props.items.length <= 1) {
			this.setState({
				currentVideo: this.props.items[0].videoUrl,
				currentMobileVideo: this.props.items[0].mobileVideoUrl,
				currentVideoPlaceholder: this.props.placeholder.resize.base64,
				currentVideoLink: this.props.items[0].ctaLink.ctaLink,
				currentVideoCtaText: this.props.items[0].ctaText
			});
		}

		if(this.props.promotionalVideo && this.props.items.length <= 1 && visited === this.props.promo) {
			this.setState({ 
				currentVideo: this.props.promotionalVideo.videoUrl,
				currentMobileVideo: this.props.promotionalVideo.mobileVideoUrl,
				currentVideoPlaceholder: this.props.promotionalPlaceholder.resize.base64,
				currentVideoLink: this.props.promotionalVideo.ctaLink.ctaLink,
				currentVideoCtaText: this.props.promotionalVideo.ctaText
			});
		}

		for (let i = 0; i < this.slideMediaWrappers.length; i++) {
			const slide = this.slideMediaWrappers[i];
			const video = slide.querySelector('video');
			if (video) {
				this.videos[i] = video;
			}
		}
		TweenMax.set(this.slideMediaWrappers, { opacity: 0, force3D: true });
		this.slideItems = [];
		for (let i = 0; i < this.slides.length; i++) {
			const slide = this.slides[i];
			this.slideItems.push(slide.querySelectorAll(`.${styles.slideOverlay} > *`));
			for (let j = 0; j < this.slideItems[i].length; j++) {
				TweenMax.set(this.slideItems[i][j], { y: 150, opacity: 0, force3D: true });
			}
		}

		//window.addEventListener('resize', this.resizeHandler);
		//this.resizeHandler();
		setTimeout(this.resizeHandler, 0);

		this.nextSlide(0, 0.5);

		if(this.props.items.length <= 1) {
			var video = document.getElementById("bgvid-0");
			video.onloadeddata = () => {
				this.reTargetVideo(this.props.promo)
			}
		}
	}

	private resizeHandler = () => {
		const videoSize = RatioUtil.scaleToFill(new Rect(16, 9), new Rect(document.body.clientWidth, this.wrapperRef.clientHeight), true);
		const videoSizeMobile = RatioUtil.scaleToFill(new Rect(768, 1094), new Rect(document.body.clientWidth, this.wrapperRef.clientHeight), true);
		const isMobile = window.innerWidth <= 768;
		if (isMobile) {
			this.wrapperHeight = this.wrapperRef.clientHeight * 0.5;
		} else {
			this.wrapperHeight = this.wrapperRef.clientHeight;
		}
		for (let i = 0; i < this.videos.length; i++) {
			const video = this.videos[i];
			//Source switching:
			const currSrc = video.getAttribute('src');
			const setMobileSrc = isMobile && video.dataset.mobileSrc !== 'undefined';
			if (setMobileSrc && currSrc !== video.dataset.mobileSrc) {
				video.setAttribute('src', video.dataset.mobileSrc);
				try {
					video.play();
				} catch (e) {}
			} else if (!setMobileSrc && video.dataset.desktopSrc !== 'undefined' && currSrc !== video.dataset.desktopSrc) {
				video.setAttribute('src', video.dataset.desktopSrc);
				try {
					video.play();
				} catch (e) {}
			}

			//resize:
			if (setMobileSrc) {
				video.style.width = videoSizeMobile.width + 'px';
				video.style.height = videoSizeMobile.height + 'px';
			} else {
				video.style.width = videoSize.width + 'px';
				video.style.height = videoSize.height + 'px';
			}
		}
	};

	private nextSlide = (overrideIndex: number = null, delayTextIn = 0) => {
		//Change index:
		let prevIndex = this.state.currentSlideIndex;
		let nextIndex = overrideIndex !== null ? overrideIndex : this.state.currentSlideIndex + 1;
		if (nextIndex >= this.slides.length) {
			nextIndex = 0;
		}

		if (this.state.currentSlideIndex !== nextIndex) {
			this.setState({ currentSlideIndex: nextIndex });
			if (this.videos[nextIndex]) {
				this.videos[nextIndex].play();
			}

			TweenMax.set(this.slides[nextIndex], { pointerEvents: 'all' });
			if (prevIndex >= 0) {
				TweenMax.set(this.slides[prevIndex], { pointerEvents: '' });
				for (let i = 0; i < this.slideItems[prevIndex].length; i++) {
					const element = this.slideItems[prevIndex][i];
					TweenMax.to(element, 0.95 + i * 0.1, { y: -75, ease: EASE_CUSTOM_IN_OUT, force3D: true });
					TweenMax.to(element, 0.65 + i * 0.1, { opacity: 0, ease: Sine.easeOut, force3D: true });
				}

				TweenMax.killTweensOf(this.uiCircles[prevIndex]);
				TweenMax.killTweensOf(this.slideItems[nextIndex]);

				TweenMax.to(this.slideMediaWrappers[prevIndex], 0.75, {
					opacity: 0,
					force3D: true,
					onComplete: () => {
						if (this.videos[prevIndex]) {
							this.videos[prevIndex].pause();
						}
						TweenMax.set(this.slideMediaWrappers[prevIndex], { scale: 1.1, force3D: true });
					}
				});
			}

			this.uiCirclesAnimation = TweenMax.fromTo(this.uiCircles[nextIndex], SLIDE_DURATION, { progress: 0 }, { progress: 0.999, ease: Linear.easeNone, onComplete: this.nextSlide });
			TweenMax.to(this.slideMediaWrappers[nextIndex], 0.75, { scale: 1, opacity: 1, force3D: true });

			for (let i = 0; i < this.slideItems[nextIndex].length; i++) {
				const element = this.slideItems[nextIndex][i];
				TweenMax.fromTo(element, 1.5 + i * 0.15, { y: 150 }, { y: 0, ease: EASE_CUSTOM_IN_OUT, force3D: true, delay: 0.5 + delayTextIn });
				TweenMax.fromTo(element, 0.9 + i * 0.15, { opacity: 0 }, { opacity: 1, ease: 'circ.inOut', force3D: true, delay: 0.5 + delayTextIn });
			}
		}
	};

	componentWillUnmount() {
		this.exitVideo();
		window.removeEventListener('resize', this.resizeHandler);
		TweenMax.killTweensOf(this.uiCircles);
		TweenMax.killTweensOf(this.slides);
	}

	private prevSlide = () => {
		if (this.state.currentSlideIndex == 0) {
			this.nextSlide(this.slides.length - 1);
		} else {
			this.nextSlide(this.state.currentSlideIndex - 1);
		}
	};

	private paginationClickCallback = (index: number) => {
		var videoWrapperRef = document.getElementById('videoWrapperRef-' + this.state.currentSlideIndex);
		var rule = CSSRulePlugin.getRule('.LandingPageGallery-module--videoWrapper--3gzQc::before');
		TweenMax.fromTo(
			videoWrapperRef,
			1,
			{
				autoAlpha: 0,
				scale: 1.5
			},
			{
				autoAlpha: 1,
				scale: 1,
				transformOrigin: '50% 50%',
				ease: Power2.easeOut
			}
		);
		this.uiCirclesAnimation.paused(false)
		TweenMax.to(rule, 0, { zIndex: 0, ease: Power2.easeOut });
		this.setState({ playing: true });
		this.nextSlide(index);
	};

	private exitVideo = (event = null) => {
		if (event) {
			event.preventDefault();
		}
		if (this.videoContainer) {
			TweenMax.to(this.videoContainer, 0.5, {
				opacity: 0,
				onComplete: () => {
					if (this.videoContainer) {
						document.body.removeChild(this.videoContainer);
						this.videoContainer = null;
					}
				}
			});
		}
	};

	handleOnSubmit = e => {
		navigate(e);
	};

	handlePlayerClick = e => {
		if (e.key === 'Enter' || e.type === 'click') {
			e.preventDefault();
			var video = document.getElementById('bgvid-' + this.state.currentSlideIndex);
			var videoWrapperRef = document.getElementById('videoWrapperRef-' + this.state.currentSlideIndex);
			var rule = CSSRulePlugin.getRule('.LandingPageGallery-module--videoWrapper--3gzQc::before');
			// @ts-ignore
			if (video.paused) {
				// @ts-ignore
				video.play();
				TweenMax.fromTo(
					videoWrapperRef,
					1,
					{
						autoAlpha: 0,
						scale: 1.5
					},
					{
						autoAlpha: 1,
						scale: 1,
						transformOrigin: '50% 50%',
						ease: Power2.easeOut
					}
				);
				this.uiCirclesAnimation.paused(false)
				TweenMax.to(rule, 0, { zIndex: 0, ease: Power2.easeOut });
				TweenMax.fromTo(this.videoControls, 0.25, { opacity: 1, scale: 0.25 }, { opacity: 1, scale: 1 })
					.reverse()
					.timeScale(2);
			} else {
				// @ts-ignore
				video.pause();
				TweenMax.fromTo(
					videoWrapperRef,
					1,
					{
						autoAlpha: 0,
						scale: 1.5
					},
					{
						autoAlpha: 1,
						scale: 1,
						transformOrigin: '50% 50%',
						ease: Power2.easeOut
					}
				);
				this.uiCirclesAnimation.paused(true)
				TweenMax.to(rule, 0, { zIndex: 1, ease: Power2.easeIn });
				TweenMax.fromTo(this.videoControls, 0.25, { opacity: 1, scale: 0.25 }, { opacity: 1, scale: 1 }).timeScale(1);
			}

			if (window.matchMedia('(prefers-reduced-motion)').matches) {
				video.removeAttribute('autoplay');
				// @ts-ignore
				video.pause();
			}

			if (!this.state.playing) {
				this.setState({ playing: true });
			} else {
				this.setState({ playing: false });
			}

			window.setTimeout(function() {
				$('#vidhomepagecontrols svg').focus();
			}, 300);
		}
	};

	render() {
		let videoTitle;
		let videoSubtitle;

		return (
			<div ref={ref => (this.wrapperRef = ref)} className={styles.wrapper}>
				{this.props.items.map((item, index) => {
					const useVideo = item.useVideo;
					const previewImage = item.image?.resize?.base64
					//previewVideoMobile = item.mobileVideoUrl
					videoTitle = item.title.title
					videoSubtitle = item.subtitle.subtitle
					
					if (this.props.promotionalVideo && this.props.items.length <= 1 && visited === this.props.promo) {
						videoTitle = this.props.promotionalVideo.title.title
						videoSubtitle = this.props.promotionalVideo.subtitle.subtitle
					}
					return (
						<>
							<div id="placeholder" key={index} className={`${styles.placeHolder} ${this.state.currentSlideIndex !== index && 'hidden'}`} style={ { backgroundImage: 'url(' +  previewImage + ')' }}>
							</div>
							<div className={styles.videoControls}>
								<div ref={ref => (this.videoControls = ref)} id="vidhomepagecontrols">
									{this.state.playing ? (
										<Pause24 onClick={(e) => this.handlePlayerClick(e)} onKeyDown={(e) => this.handlePlayerClick(e)} />
									) : (
										<Play24 onClick={(e) => this.handlePlayerClick(e)} onKeyDown={(e) => this.handlePlayerClick(e)} />
									)}
								</div>
							</div>
							<Swipeable
								onSwipeLeft={this.props.animationDirection === 1 ? this.paginationClickCallback : this.prevSlide}
								onSwipeRight={this.props.animationDirection === 1 ? this.prevSlide : this.paginationClickCallback}
								key={item.id}>
								<div onClick={() => this.handleOnSubmit(this.state.currentVideoLink)} className={styles.slide} ref={ref => (this.slides[index] = ref)} key={item.id}>
									<div ref={ref => (this.slideMediaWrappers[index] = ref)} className={styles.slideMediaWrapper}>
										{
										// @ts-ignore
										!useVideo && <GatsbyImageWrapper alt={item.title} outerWrapperClassName={styles.imageWrapper} fluid={item.image.desktop} />}
										{
										// @ts-ignore
										!useVideo && <GatsbyImageWrapper alt={item.title} outerWrapperClassName={styles.imageWrapperMobile} fluid={item.image.mobile} />}
										{useVideo && (
											<div
												className={styles.videoWrapper}
												ref={div => (this.VideoWrapperRef = div)}
												id={`videoWrapperRef-${index}`}
											>
												<video id={`bgvid-${index}`} muted playsInline preload="true" loop data-mobile-src={`${this.state.currentMobileVideo ? this.state.currentMobileVideo : item.mobileVideoUrl}`} data-desktop-src={`${this.state.currentVideo ? this.state.currentVideo : item.videoUrl}`} />
											</div>
										)}
									</div>
									{
										// this.state.currentLocation !== 'QA' &&
										<div className={styles.slideOverlayWrapper}>
											<div className={`container-padding ${styles.slideOverlay}`}>
												<h1 className={`text-style-h1`}>
													{videoTitle}
												</h1>
												<p className={`text-style-body`}>
													{videoSubtitle}
												</p>
												<a className={`text-style-body ${styles.ctaLink} ${styles.ctaLinkNormal}`} href={this.state.currentVideoLink ? this.state.currentVideoLink : item.ctaLink?.ctaLink}>
													{this.state.currentVideoCtaText ? this.state.currentVideoCtaText : item.ctaText}
												</a>
											</div>
										</div>
									}
								</div>
							</Swipeable>
						</>
					);
				})}
				{
					this.props.items.length > 1 && (
						<ul className={styles.pagination}>
							{this.props.items.map((item, index) => (
								<UICircleProgress
									clickCallback={this.paginationClickCallback}
									index={index}
									ref={ref => (this.uiCircles[index] = ref)}
									key={item.id}
									className={styles.paginationItem + (index === this.state.currentSlideIndex ? ' active' : '')}
								/>
							))}
						</ul>
					)
				}
			</div>
		);
	}
}

export default LandingPageGallery;

export const query = graphql`
	fragment LandingPageGalleryFragment on ContentfulPageHome {
		landingGallery {
			id
			title {
				title
			}
			subtitle {
				subtitle
			}
			useVideo
			videoUrl
			mobileVideoUrl
			ctaText
			ctaLink {
				ctaLink
			}
			image {
				resize(width: 1280, height: 720) {
					base64
				}
			}
		}
	}
`;
