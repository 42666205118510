import * as React from 'react';
import { Component } from 'react';
import * as styles from './ModuleFeaturedLink.module.scss';
import ViewableMonitor from '../ui/ViewableMonitor';
// @ts-ignore
import { moduleFeaturedLinkQuery } from '../gatsby-queries';
import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImageWrapper } from '../ui/GatsbyImageWrapper';
import { FormattedMessage } from 'react-intl';
import { getPagePath } from '../../utils/URLHelper';
import GatsbyLink from 'gatsby-link';

class ModuleFeaturedLink extends Component<{ heroImage: moduleFeaturedLinkQuery; }> {
	render() {
		return (
			
			<div className={`module-margin ${styles.bgGrey}`}>
				<div className={`${styles.imageWrapper}`}>
					{
						// @ts-ignore
						<GatsbyImageWrapper fluid={this.props.heroImage.contentfulAsset.sizes} alt={this.props.heroImage.contentfulAsset.title} />
					}
				</div>
				<div className={`${styles.innerWrapper}`}>
					<div className={`${styles.wrapper} container-padding`}>
						<ViewableMonitor>
							<div className={`${styles.title} ${styles.textWrapper}`}>
								<div className={`text-style-h2`}>
									<h2><FormattedMessage id={'education_city_title'} /></h2>
								</div>
							</div>
						</ViewableMonitor>
						<ViewableMonitor>
							<div className={`text-style-body ${styles.detailsWrapper}`}>
								<FormattedMessage id={'education_city_content'} />
								<GatsbyLink to={getPagePath('', 'ec')} data-swiftype-index="false" className={`text-style-body ${styles.ctaLink}`}>
                                    <FormattedMessage id={'education_city_link'} />
                                </GatsbyLink>
							</div>
						</ViewableMonitor>
					</div>
				</div>
			</div>
		);
	}
}

export default props => (
    <StaticQuery
            query={graphql`
                query moduleFeaturedLinkQuery {
                    contentfulAsset(title: {eq: "educationcitywidehero"}) {
                        title
                        sizes(maxWidth: 960, quality: 85) {
                            ...GatsbyContentfulSizes_withWebp_noBase64
                        }
                    }
                }
    `}
	// @ts-ignore
    render={data => <ModuleFeaturedLink heroImage={data as moduleFeaturedLinkQuery} {...props} />}
    />
);