import { useStaticQuery, graphql } from "gatsby"

export const PlaceHolderImage = () => {
    const data = useStaticQuery(
        graphql`
            query placeHolderImageQuery {
                contentfulAsset(title: {eq: "qfbrowsenews"}) {
                    contentful_id
                    title
                    sizes(maxWidth: 960, quality: 85) {
                        ...GatsbyContentfulSizes_withWebp_noBase64
                    }
                }
            }
        `)
    return data
};
