import * as React from 'react';
import { navigateTo } from 'gatsby-link';
import LandingPageGallery from '../components/ContentfulModules/LandingPageGallery';
import PageWrapper, { IPageProps } from './PageWrapper';
import { HomeQuery } from '../gatsby-queries';
import { Helmet } from 'react-helmet';
import ModulesWrapper from '../components/ModulesWrapper';
import Module3Links from '../components/ContentfulModules/Module3Links';
import ModuleFeaturedLink from '../components/ContentfulModules/ModuleFeaturedLink';
import ModuleHomeNewsletter from '../components/ContentfulModules/ModuleHomeNewsletter';
import NewsList from '../components/NewsList';
import EventsList from '../components/EventsList';
import { graphql } from 'gatsby';

interface Props extends IPageProps {
	data: HomeQuery;
}

class IndexPage extends React.Component<Props> {
	private gallery: LandingPageGallery;
	private menuNormalMode = false;

	componentDidMount(): void {
		this.removeProgressIndicator();
		window.addEventListener('resize', this.checkMenuScroll);
		window.addEventListener('scroll', this.checkMenuScroll);
		this.checkMenuScroll();
		setTimeout(this.checkMenuScroll, 0);

		const cookieLang = typeof window !== 'undefined' && window.localStorage.getItem('nf_lang');
		if (this.props.location.pathname === '/ar' && cookieLang === 'en') {
			return navigateTo('/');
		}

		if (this.props.pathContext.id === 'home') {
			var element = document.getElementById('breadcrumb');
			if (!element.classList.contains('hidden')) {
				element.classList.add('hidden');
			}
		}
	}

	private removeProgressIndicator() {
		var indicatorCheck = typeof document !== 'undefined' && document.getElementById('gatsby-plugin-page-progress');
		if (indicatorCheck) indicatorCheck.remove();
	}

	componentWillUnmount(): void {
		window.removeEventListener('resize', this.checkMenuScroll);
		window.removeEventListener('scroll', this.checkMenuScroll);
		document.body.classList.remove('menu-normal');
	}

	private checkMenuScroll = () => {
		if (window.pageYOffset > this.gallery.wrapperHeight - 100) {
			if (!this.menuNormalMode) {
				this.menuNormalMode = true;
				document.body.classList.add('menu-normal');
			}
		} else {
			if (this.menuNormalMode) {
				this.menuNormalMode = false;
				document.body.classList.remove('menu-normal');
			}
		}
	};

	componentDidUpdate() {
		this.removeProgressIndicator();
	}

	render() {
		const animationDirection = this.props.pathContext.languageCode === 'ar-QA' ? -1 : 1;
		this.removeProgressIndicator();
		return (
			// @ts-ignore
			<PageWrapper location={this.props.location} pageData={this.props.data.contentfulPageHome} type="home" title={this.props.data.contentfulPageHome.title} metaTitle={this.props.data.contentfulPageHome.metaTitle} pathContext={this.props.pathContext}>
				<div>
					<div data-swiftype-index="false">
						{
							//@ts-ignore:
							<Helmet>
								<html className={'page-home'} />
							</Helmet>
						}
						<LandingPageGallery
							ref={ref => (this.gallery = ref)}
							items={this.props.data.contentfulPageHome.landingGallery}
							placeholder={this.props.data.contentfulPageHome.preloadPlaceholderImage}
							// @ts-ignore
							promo={this.props.data.promotionalVideoTitleEnglish.promotionalVideo && this.props.data.promotionalVideoTitleEnglish.promotionalVideo.title.title}
							promotionalPlaceholder={this.props.data.contentfulPageHome.promotionalVideoPlaceholder}
							promotionalVideo={this.props.data.contentfulPageHome.promotionalVideo}
							animationDirection={animationDirection}
						/>
					</div>
					<Module3Links />
					<ModuleFeaturedLink />
					<ModulesWrapper
						languageCode={this.props.pathContext.languageCode}
						upcomingEventsData={this.props.pathContext.upcomingEvents}
						childrenLast={true}
						hasHeroImage={false}
						data={this.props.data.contentfulPageHome.modulesWrapper}
					></ModulesWrapper>
					{this.props.pathContext.latestPageArticles && (
						// @ts-ignore
						<NewsList
							articles={this.props.pathContext.latestPageArticles}
							languageCode={this.props.location.pathname === '/ar/' ? 'ar-QA' : 'en-US'}
							animationDirection={animationDirection}
							title={this.props.pathContext.languageCode === 'ar-QA' ? 'أبرز الفعاليات' : 'Featured Events'}
						/>
					)}

					{this.props.pathContext.upcomingPageEvents && (
						<EventsList
							animationDirection={animationDirection}
							events={this.props.pathContext.upcomingPageEvents}
							title={this.props.pathContext.languageCode === 'ar-QA' ? 'أبرز الفعاليات' : 'Featured Events'} />
					)}
					<div className={'bgGrey'}>
						<div className="container-padding">
							{
								// @ts-ignore
								<ModuleHomeNewsletter currLang={this.props.pathContext.languageCode} />
							}
						</div>
					</div>
				</div>
			</PageWrapper>
		);
	}
}

export default IndexPage;

export const pageQuery = graphql`
	query HomeQuery($languageCode: String) {
		contentfulPageHome(contentful_id: { eq: "1UkArCmwqoGeweAo8yiMgG" }, node_locale: { eq: $languageCode }, title: { ne: "WORKAROUND. DO NOT DELETE." } ) {
			title
			slug
			preloadPlaceholderImage {
				resize(width: 1280, height: 720) {
					base64
				}
			}
			...LandingPageGalleryFragment
			promotionalVideoPlaceholder {
				resize(width: 1280, height: 720) {
					base64
				}
			}
			promotionalVideo {
				title {
					title
				}
				subtitle {
					subtitle
				}
				useVideo
				videoUrl
				mobileVideoUrl
				ctaText
				ctaLink {
					ctaLink
				}
			}
			modulesWrapper {
				...ContentfulModuleWrapperFragment
			}
			showStatementNotification
			metaTitle
			metaDescription {
				metaDescription
			}
		}
		promotionalVideoTitleEnglish: contentfulPageHome(contentful_id: { eq: "1UkArCmwqoGeweAo8yiMgG" }, node_locale: { eq: "en-US" }) {
			promotionalVideo {
				title {
					title
				}
			}
		}
	}
`;
